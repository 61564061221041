// @flow
import React, { PureComponent } from 'react'
import styled from 'styled-components'
import FormGroup from 'reactstrap/lib/FormGroup'
import { unreadIndicator } from '../EmailUtils'
import utmLinkGenerator from '../../../utils/UTMLinkGenerator'
import Headline from '../../../utils/Headline'
import HeadlineUnreadIndicator from './StyledHeadlineUnreadIndicator'
import Link from './StyledLink'
import Submit from './StyledSubmit'
import TextBox from './StyledTextBox'
import MojeidLink from './StyledMojeidLink'
import Loader from '../../../utils/Loader'
import { ButtonCol, Form, FormRow, InputCol, LoginRow } from './StyledContainers'
import { RememberCheckbox, RememberLabel } from './StyledRememberCheckbox'
import type { InboxType, WidgetType } from '../Types'
import { getAdsPersonalizationConsent } from '../../../../../lib/utils/didomiConsent'
import { sendWidgetClick } from '../../../utils/ga4Events'

const REMEMBER_ID = 'ego_expires'
const REMEMBER_VALUE = '1209600'

export const HeadlineWrapper = styled.div`
  height: 24px;
  margin-bottom: 10px;
  position: relative;
`

type Props = {|
  embedded?: boolean,
  googleAnalytics?: any,
  inbox: InboxType,
  widget: WidgetType,
  loading: boolean,
  variant: string
|}

type State = {|
  consent: number
|}

type Target = {|
  checked: boolean
|}

class EmailSummary extends PureComponent<Props, State> {
  constructor (props: Props) {
    super(props)
    this.state = {
      consent: 0
    }
  }

  componentDidMount () {
    window.didomiOnReady.push(() => {
      this.setState({
        consent: Number(getAdsPersonalizationConsent())
      }
      )
    })
  }

  handleRegistrationClick () {
    sendWidgetClick({
      name: this.props.widget.name,
      click: {
        type: 'link',
        value: 'registration'
      }
    })
  }

  handleRememberMe (target: Target) {
    const { checked } = target
    if (!checked) {
      return
    }
    sendWidgetClick({
      name: this.props.widget.name,
      click: {
        type: 'checkbox',
        value: 'rememberMe'
      }
    })
  }

  handleLogin () {
    sendWidgetClick({
      name: this.props.widget.name,
      click: {
        type: 'submit',
        value: 'login'
      }
    })
  }

  render () {
    const {
      props: {
        embedded,
        inbox: {
          summary: {
            unread = 0
          } = {},
          summary = {}
        } = {},
        loading,
        widget,
        widget: {
          meta: {
            form,
            registration,
            mojeId
          } = {},
          utmMedium
        },
        variant
      },
      state: {
        consent
      }
    } = this
    const registrationLink = utmLinkGenerator.createLink(registration.location, { utmMedium, utmTerm: 'registrovat' })

    return (
      <React.Fragment>
        {variant !== 'EcoIdTransition' && !embedded && <HeadlineWrapper>
          <Headline widget={widget}>
            {
              (Object.keys(summary).length > 0) &&
             <HeadlineUnreadIndicator className={unread === 0 ? 'grey' : ''}>
               {unreadIndicator(unread)}
             </HeadlineUnreadIndicator>
            }
          </Headline>
        </HeadlineWrapper>}
        <Form action={form.action} method='POST' embedded={embedded}>
          {loading && <Loader/>}
          <LoginRow>
            <InputCol>
              <TextBox type='text' name='ego_user' placeholder='Jméno'/>
              <TextBox type='password' name='ego_secret' placeholder='Heslo'/>
            </InputCol>
            <ButtonCol>
              <Submit onClick={this.handleLogin} type='submit'>
              Přihlásit
              </Submit>
            </ButtonCol>
          </LoginRow>
          <input type='hidden' name='hasConsent' value={consent}/>
          <FormRow>
            {
              mojeId &&
              <MojeidLink href={utmLinkGenerator.createLink(mojeId.action, { utmMedium })}>
                Přihlásit se do e-mailu přes mojeID
              </MojeidLink>
            }
          </FormRow>
          <FormRow>
            <div>
              <FormGroup>
                <RememberCheckbox
                  onChange={this.handleRememberMe}
                  type='checkbox'
                  id={REMEMBER_ID}
                  name={REMEMBER_ID}
                  value={REMEMBER_VALUE}/>
                <RememberLabel for={REMEMBER_ID}>
                zůstat přihlášen(a)
                </RememberLabel>
              </FormGroup>
            </div>
            <div>
              <Link onClick={this.handleRegistrationClick} href={registrationLink}>
                {registration.text}
              </Link>
            </div>
          </FormRow>
        </Form>
      </React.Fragment>
    )
  }
}

export default EmailSummary
