import React from 'react'
import styled from 'styled-components'
import CloseIcon from './CloseIcon'
import { SIZES } from '../../../constants'
import { sendEvent } from '../../utils/ga4Events'

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`

const ModalContent = styled.div`
  background-color: white;
  padding: 24px;
  border-radius: 8px;
  max-width: 90%;
  width: 600px;
  position: relative;
`

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`

const CloseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Title = styled.h2`
  margin: 0;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  text-align: left;

  @media (max-width: ${SIZES.BREAKPOINT_MD}px) {
    font-size: 16px;
    line-height: 20px;
  }
`

const Paragraph = styled.p`
  margin-bottom: 16px;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  text-align: left;

  @media (max-width: ${SIZES.BREAKPOINT_MD}px) {
    font-size: 12px;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`

const ConfirmButton = styled.button`
  padding: 12px 30px 12px 30px;
  border: none;
  border-radius: 44px;
  color: white;
  cursor: pointer;
  background: linear-gradient(90deg, #FF3633 0%, #FF8D4C 100%);
`

const BUTTON_TEXT = 'Přejděte na nové Centrum už dnes'

const BetaHpxModal = ({ onClose, onConfirm }) => {
  const handleConfirm = () => {
    sendEvent({
      event: 'testBar_click',
      item: {
        variant: 'old',
        click: 'https://beta.centrum.cz/',
        value: BUTTON_TEXT,
        url: window.location.href
      }
    })
    onConfirm()
  }

  return (
    <ModalOverlay>
      <ModalContent>
        <ModalHeader>
          <Title>Vyzkoušejte si nové Centrum.cz</Title>
          <CloseButton onClick={onClose}><CloseIcon /></CloseButton>
        </ModalHeader>
        <Paragraph>
          Pokrok nezastavíš! A víme, že si zasloužíte víc služeb a informací. Proto jsme usilovně pracovali, abychom Bobíkovi i vám vytvořili zcela nový prostor pro přístup k informacím a službám. Pojďte s námi jako prví otestovat Centrum.cz nové generace.
        </Paragraph>
        <Paragraph>
          Máme pro vás více článků, které můžete číst přímo na stránce Centrum. Nekonečné množství obsahu doplňují služby, jako je e-mail, hledání, počasí, horoskopy i další.
        </Paragraph>
        <Paragraph>
          A to je jen začátek. V budoucnu se můžete těšit i na další vylepšení a novinky v podobě dalšího obsahu a služeb, které vám, jak pevně věříme, zpříjemní každý den.
        </Paragraph>
        <ButtonWrapper>
          <ConfirmButton onClick={handleConfirm}>{BUTTON_TEXT}</ConfirmButton>
        </ButtonWrapper>
      </ModalContent>
    </ModalOverlay>
  )
}

export default BetaHpxModal
