import styled from 'styled-components'
import Input from 'reactstrap/lib/Input'

export default styled(Input)`
  background-color: #fff;
  border: 1px solid ${props => props.theme.email.summary.input.border};
  border-radius: ${props => props.theme.email.summary.input.borderRadius};
  display: block;
  font-size: 13px;
  height: 32px;
  margin-bottom: 6px;
  padding: 0 7px;
  width: 100%;

  &:focus {
    box-shadow: none;
    border: 1px solid ${props => props.theme.email.summary.input.borderFocus};
  }
`
