import React, { forwardRef, useState } from 'react'
import { UserAvatar } from './UserAvatar'
import { InitialsAvatar } from './InitialsAvatar'
import { DefaultAvatar } from './DefaultAvatar'
import { SIZE } from '../../../../ui/Avatar/StyledAvatar'
import styled from 'styled-components'

export const Avatar = forwardRef(({
  userInfo,
  ecoIdentity,
  onClick,
  isDefaultKetchup = false
}, ref) => {
  const [userAvatarError, setUserAvatarError] = useState(false)

  if (!userInfo) {
    return <div style={{ width: `${SIZE}px` }}/>
  }

  const { firstName = '', lastName = '', avatar = '' } = userInfo

  if (!userAvatarError && avatar !== '') {
    return (
      <UserAvatar
        avatar={avatar}
        ecoIdentity={ecoIdentity}
        onClick={onClick}
        onError={(_) => {
          setUserAvatarError(true)
        }}
        ref={ref}
      />
    )
  }

  if (firstName !== '' || lastName !== '') {
    return (
      <InitialsAvatar
        firstName={firstName}
        lastName={lastName}
        onClick={onClick}
        ref={ref}
      />
    )
  }

  if (isDefaultKetchup) {
    return (
      <StyledImage
        src={`${UNVERSIONED_STATICS_PATH}images/ecoidpromo/moving_ketchup.webp`}
        alt='Animace kečupu'
        onClick={onClick}
      />
    )
  }

  return <DefaultAvatar onClick={onClick} ref={ref} />
})

const StyledImage = styled.img`
  max-width: 38px;
`
