import { LoginIcon } from '../components/icons/Login/LoginIcon'
import { StyledMobileUserMenuLink } from './MobileUserMenu'
import { getLinkTarget } from '../components/utils/getLinkTarget'
import {
  MORE_INFO_ECO_ID_URL,
  StyledLoginModalButton,
  StyledLoginModalMessage,
  StyledLoginModalTitle
} from '../components/widgets/Header/HeaderLogin/LoginModal/LoginModal'
import React from 'react'
import styled from 'styled-components'

export const MobileUserMenuLoggedOff = ({
  loginText,
  onLogin,
  variant,
  activeDynamicLayout
}) => {
  const title = 'Připravte se na změnu'
  const message = 'Přejděte na nové přihlášení do emailu i dalších služeb už dnes.'
  const loginLabel = 'NOVÉ PŘIHLÁŠENÍ'

  return (variant === 'EcoIdComplete'
    ? (
      <StyledMobileUserMenuLink onClick={onLogin}>
        <LoginIcon />
        {loginText}
      </StyledMobileUserMenuLink>
    )
    : (
      <StyledMobileUserMenuLoggedOffContainer>
        <StyledLoginModalTitle>{title}</StyledLoginModalTitle>
        <StyledLoginModalMessage>{message}</StyledLoginModalMessage>
        {variant === 'EcoIdTransition' &&
          <a
            href={MORE_INFO_ECO_ID_URL}
            target={getLinkTarget(activeDynamicLayout)}
          >
            Více informací
          </a>
        }
        <StyledLoginModalButton onClick={onLogin}>
          {loginLabel}
        </StyledLoginModalButton>
      </StyledMobileUserMenuLoggedOffContainer>
    )
  )
}

export const StyledMobileUserMenuLoggedOffContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 20px;
`
