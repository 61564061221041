import React, { Component } from 'react'
import Col from 'reactstrap/lib/Col'
import styled from 'styled-components'
import SectionsMenu from './SectionsMenu'
import SearchForm from './SearchForm'
import { GA_SEARCH_EVENT_CATEGORY, SECTIONS, SECTION_NAMES, SIZES } from '../../../../constants'

const SearchWrapper = styled(({ $variant, ...props }) => <Col {...props} />)`
  padding: 0;
  flex: 1 1 auto;

  @media (min-width: ${SIZES.BREAKPOINT_XS}px) {
    margin-top: 13px;
    flex: 2 2 487px;
    display: -ms-flexbox;
    -ms-flex-direction: column;
    -ms-flexbox: 2 2 487px;
    margin-right: ${({ $variant }) => $variant === 'EcoIdComplete' || $variant === 'EcoIdTransition' ? '44px' : '0'};
    margin-bottom: ${({ $variant }) => $variant === 'EcoIdComplete' || $variant === 'EcoIdTransition' ? '0' : '-4px'};
    max-width: ${({ $variant }) => $variant === 'EcoIdComplete' || $variant === 'EcoIdTransition' ? 'initial' : '487px'};
  }
`

class Search extends Component {
  constructor (props) {
    super(props)

    this.state = {
      activeSection: this.mergeStaticAndConfigSection(SECTION_NAMES.INTERNET)
    }

    this.handleSectionClick = this.handleSectionClick.bind(this)
  }

  componentWillReceiveProps (nextProps) {
    const {
      isFixed
    } = nextProps

    const {
      activeSection
    } = this.state

    if (isFixed && activeSection.name !== SECTION_NAMES.INTERNET) {
      this.setState({
        activeSection: this.mergeStaticAndConfigSection(SECTION_NAMES.INTERNET)
      })
    }
  }

  handleSectionClick (sectionName) {
    const activeSection = this.mergeStaticAndConfigSection(sectionName)

    this.setState({
      activeSection
    })

    if (this.props.eventsTracker) {
      this.props.eventsTracker.event({
        category: GA_SEARCH_EVENT_CATEGORY,
        action: activeSection.text
      })
    }
  }

  mergeStaticAndConfigSection (sectionName) {
    const {
      props: {
        widget: {
          meta: {
            sections = {}
          }
        }
      }
    } = this
    const configSection = sections[sectionName] || {}
    const staticSection = SECTIONS[sectionName] || {}

    return Object.assign({}, staticSection, configSection)
  }

  render () {
    const {
      isFixed,
      proxyOptions,
      widget,
      googleAnalytics,
      widget: {
        utmMedium,
        utmSource
      },
      secret,
      variant
    } = this.props

    const {
      activeSection
    } = this.state

    return (
      <SearchWrapper className={isFixed ? 'fixed' : ''} $variant={variant}>
        {
          !isFixed &&
          <SectionsMenu activeSection={activeSection} onSectionClick={this.handleSectionClick}/>
        }
        <SearchForm
          widget={widget}
          activeSection={activeSection}
          utmMedium={utmMedium}
          utmSource={utmSource}
          proxyOptions={proxyOptions}
          secret={secret}
          googleAnalytics={googleAnalytics}
          section={activeSection}
        />
      </SearchWrapper>
    )
  }
}

export default Search
