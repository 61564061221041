import React, { forwardRef } from 'react'
import styled from 'styled-components'
import { login } from '../../../../../utils/login'

export const HeaderLoginButton = forwardRef(({ ecoIdentity, children, variant, onClick }, ref) => {
  const handleClick = () => {
    const loginLocation = ecoIdentity.login.location
    const clientId = ecoIdentity.clientId

    if (variant === 'EcoIdTransition') {
      onClick()
    } else if (variant === 'EcoIdComplete') {
      login({ loginLocation, clientId })
    }
  }

  return (
    <StyledButton
      onClick={handleClick}
      innerRef={ref}
    >
      {children}
    </StyledButton>
  )
})

const StyledButton = styled.button`
  color: ${props => props.theme.headerLogin.loginButton.color};
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  background: ${({ theme }) => theme.headerLogin.loginButton.backgroundColor};
  border: none;
  padding-bottom: 12px;
  
  &:hover {
    color: ${({ theme }) => theme.headerLogin.loginButton.hover.color};
  }
`
