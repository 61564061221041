/* @flow */
import * as React from 'react'
import EmailSummary from './EmailSummary'
import EmailFull from './EmailFull'
import { isResponseFull } from './EmailUtils'
import { EmailConsumer } from '../../../context/Email'
import { type WidgetType } from './Types'

type InnerComponentProps = {|
  embedded: boolean,
  widget: WidgetType,
  googleAnalytics?: any,
  variant: string
|}

export default class Email extends React.PureComponent<InnerComponentProps> {
  render () {
    const {
      embedded = false,
      widget,
      googleAnalytics,
      variant
    } = this.props

    return (
      <EmailConsumer googleAnalytics={googleAnalytics}>
        { ({ handleLogoutClick, inbox, isLoadingInbox }) =>
          <div id={widget.name}>
            {isResponseFull(inbox) ? (
              <EmailFull
                embedded={embedded}
                handleLogoutClick={handleLogoutClick}
                inbox={inbox}
                isLoading={isLoadingInbox}
                widget={widget}
              />
            ) : (
              <EmailSummary googleAnalytics={googleAnalytics} embedded={embedded} widget={widget} inbox={inbox} loading={isLoadingInbox} variant={variant}/>
            )}
          </div>
        }
      </EmailConsumer>
    )
  }
}
