import styled from 'styled-components'
import Link from '../../../utils/Link'

export default styled(Link)`
  color: #404040;
  font-size: 12px;
  line-height: 20px;
  position: relative;
  vertical-align: middle;
  margin-bottom: 7px;

  &:before {
    background: url(${STATICS_PATH}images/email/mojeid.png) no-repeat center / cover;
    content: '';
    display: inline-block;    
    height: 16px;
    margin-right: 5px;
    vertical-align: middle;
    width: 16px;
  }
`
