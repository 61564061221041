const fontColor = '#333'
const highlightColor = '#1d8ce5'
const widgetBackgroundColor = '#a8d7ef'
const darkTextColor = '#403f3f'
const buttonBackgroundColor = '#26a3e0'
const buttonBorderColor = '#beced7'
const buttonColor = '#fff'
const inboxLink = '#000'
const shadowColor = '#00000040'
const inboxBorder = '#dcdcdc'
const buttonBackgroundHoverColor = '#1f9dd9'
const emailBoxColor = '#3e5a67'
const mobileUserMenuColor = '#1359c3'

const blueButtonStyles = {
  color: buttonColor,
  background: buttonBackgroundColor,
  border: buttonBorderColor,
  hover: {
    background: buttonBackgroundHoverColor,
    border: '#beced7'
  }
}

export default {
  name: 'volny',
  header: {
    logo: {
      bottom: 2,
      height: 35
    },
    holidayLogo: {
      top: 15
    },
    search: {
      sections: {
        color: '#404040',
        activeColor: highlightColor
      },
      form: {
        autocomplete: {
          color: highlightColor
        },
        input: {
          borderColor: '#cdd1d4',
          borderColorFocus: '#cdd1d4',
          color: '#000'
        }
      }
    },
    promolinkheader: {
      color: 'rgb(219, 0, 32)',
      darkColor: 'rgb(0, 0, 0)'
    },
    email: {
      indicatorColor: highlightColor,
      linkColor: fontColor
    }
  },
  covid: {
    textHover: highlightColor
  },
  dynamicLead: {
    firstBox: {
      backgroundColor: '#cae9f8',
      backgroundColorHover: '#cae9f8',
      color: darkTextColor
    },
    smallBox: {
      color: darkTextColor
    }
  },
  mobileTabs: {
    background: widgetBackgroundColor
  },
  email: {
    summary: {
      background: widgetBackgroundColor,
      registrationLink: {
        color: fontColor,
        colorHover: fontColor
      },
      checkbox: {
        colorChecked: '#e8ac39'
      },
      submit: {
        color: '#404040',
        border: '#8fb9ce',
        borderRadius: '5px',
        background: '#ffdd40',
        borderHover: '#8fb9ce',
        backgroundHover: '#ffdd40'
      },
      input: {
        border: '#cdd1d4',
        borderFocus: '#cdd1d4',
        borderRadius: '5px'
      }
    },
    full: {
      color: '#1a85da',
      backgroundColor: '#a8d7ef',
      number: '#000',
      bgNumber: '#ffdd40',
      borderNumber: '#ffdd40',
      link: inboxLink,
      border: inboxBorder,
      subject: {
        color: '#2c6796',
        colorHover: highlightColor
      },
      toggleIcon: {
        background: `${STATICS_PATH}images/email/arrow-centrum.png`
      },
      inboxIcon: {
        background: `${STATICS_PATH}images/email/inbox-volny.svg`
      }
    },
    identita: {
      textColor: emailBoxColor,
      buttonTextColor: buttonColor,
      buttonColor: highlightColor,
      buttonHoverColor: highlightColor
    }
  },
  mobileUserMenu: {
    color: mobileUserMenuColor,
    iconColor: mobileUserMenuColor,
    hoverColor: mobileUserMenuColor,
    borderColor: mobileUserMenuColor
  },
  headerLogin: {
    shadowColor,
    dividerColor: inboxBorder,
    icon: {
      color: buttonBackgroundColor,
      hover: {
        color: buttonBackgroundHoverColor
      }
    },
    unreadMessagesCount: {
      color: buttonColor,
      backgroundColor: buttonBackgroundColor,
      zero: {
        backgroundColor: '#aaa'
      }
    },
    newLabel: {
      color: buttonBackgroundColor
    },
    loginButton: {
      color: buttonBackgroundColor,
      backgroundColor: buttonColor,
      hover: {
        color: buttonBackgroundHoverColor
      }
    },
    loginModal: {
      backgroundColor: buttonColor,
      title: {
        color: fontColor
      },
      message: {
        color: fontColor
      },
      loginButton: {
        color: '#404040',
        backgroundColor: '#ffdd40',
        borderColor: buttonBorderColor,
        hover: {
          backgroundColor: buttonBackgroundHoverColor,
          borderColor: buttonBorderColor
        }
      }
    },
    userMenu: {
      backgroundColor: buttonColor,
      item: {
        color: buttonBackgroundColor,
        hover: {
          color: buttonColor,
          backgroundColor: buttonBackgroundHoverColor
        }
      },
      basicInfo: {
        color: fontColor
      }
    },
    avatar: {
      borderColor: buttonBackgroundColor,
      color: buttonBackgroundColor
    }
  },
  emailFull: {
    inbox: {
      color: '#404040',
      background: '#ffd640',
      backgroundHover: '#ffdd40'
    },
    logout: {
      color: '#1d8ce5',
      colorHover: '#1a85da'
    },
    message: {
      color: '#fff',
      background: buttonBackgroundHoverColor,
      backgroundHover: buttonBackgroundHoverColor
    }
  },
  ecoIdLoginPromo: {
    container: {
      backgroundColor: '#ffdd40'
    },
    text: {
      color: 'black'
    },
    button: {
      color: 'white',
      backgroundColor: buttonBackgroundColor,
      backgroundHoverColor: buttonBackgroundHoverColor
    },
    moreInfo: {
      color: fontColor,
      hoverColor: fontColor
    }
  },
  weather: {
    button: blueButtonStyles
  },
  exchange: {
    button: blueButtonStyles
  },
  tip: {
    background: widgetBackgroundColor,
    color: darkTextColor
  },
  footer: {
    color: '#403f3f',
    colorLink: '#403f3f'
  },
  services: {
    color: highlightColor
  },
  jobs: {
    arrowUrl: `${STATICS_PATH}images/jobs/arrow-volny.png`,
    color: highlightColor,
    colorHover: highlightColor
  },
  newsfeed: {
    button: blueButtonStyles
  },
  headline: {
    color: highlightColor,
    colorHover: highlightColor
  },
  moreLink: {
    color: highlightColor,
    colorHover: highlightColor
  },
  link: {
    colorHover: '#404040'
  },
  coronaHighlight: {
    color: highlightColor
  },
  featured: {
    video: {
      backgroundColor: buttonBackgroundColor,
      borderColor: buttonBorderColor,
      color: buttonColor,
      iconInvert: '0%'
    }
  },
  button: blueButtonStyles,
  regional: {
    color: buttonColor,
    newLabelBorder: buttonBorderColor,
    newLabelBackground: buttonBackgroundColor
  }
}
