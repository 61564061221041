import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { SIZES } from '../../../constants'
import InfoIcon from './InfoIcon'
import BetaHpxModal from './BetaHpxModal'
import { handleClick } from './handleClick'
import { sendEvent } from '../../utils/ga4Events'

const BarContainer = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  margin: 24px 12px 16px 0px;
  padding: 8px 16px 8px;
  border-radius: 8px 0px 0px 8px;
  background: linear-gradient(90deg, #FF3232 0%, #FF924E 100%);
  cursor: pointer;
`

const BarContent = styled.div`
  gap: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`

const BarText = styled.span`
  color: white;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  text-align: left;
  text-transform: uppercase;

  @media (max-width: ${SIZES.BREAKPOINT_MD}px) {
    font-size: 14px;
    font-weight: 700;
  }
`

const BarSubText = styled.span`
  color: white;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-align: left;
`

const DogImage = styled.img`
  position: absolute;
  top: 50%;
  right: -12px;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  transform: translateY(-50%);

  @media (max-width: ${SIZES.BREAKPOINT_MD}px) {
    width: 80px;
    height: 80px;
  }
`

const InfoIconWrapper = styled.div`
  position: absolute;
  top: 50%;
  right: 60px;
  transform: translateY(-50%);
  cursor: pointer;

  @media (max-width: ${SIZES.BREAKPOINT_MD}px) {
    right: 76px;
  }
`

const BetaHpxBar = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  useEffect(() => {
    sendEvent({
      event: 'testBar_impression',
      item: {
        variant: 'old',
        url: window.location.href
      }
    })
  }, [])

  const openModal = () => {
    setIsModalOpen(true)
    sendEvent({
      event: 'testBar_click',
      item: {
        variant: 'old',
        click: 'https://beta.centrum.cz/',
        value: 'Vyzkoušejte si nové CENTRUM.cz',
        url: window.location.href
      }
    })
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  return (
    <React.Fragment>
      <BarContainer onClick={openModal}>
        <BarContent>
          <BarText>Nový domov bro bobíka</BarText>
          <BarSubText>Vyzkoušejte si nové CENTRUM.cz</BarSubText>
        </BarContent>
        <InfoIconWrapper>
          <InfoIcon />
        </InfoIconWrapper>
        <DogImage src={`${UNVERSIONED_STATICS_PATH}images/hpxbetabar/bobik.png`} alt='Dog' />
      </BarContainer>
      {isModalOpen && <BetaHpxModal onClose={closeModal} onConfirm={handleClick} />}
    </React.Fragment>
  )
}

export default BetaHpxBar
