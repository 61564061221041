// @flow
import React from 'react'
import { unreadIndicator } from '../EmailUtils'
import PersistedComponent from '../../PersistedComponent'
import utmLinkGenerator from '../../../utils/UTMLinkGenerator'
import Loader from '../../../utils/Loader'
import Wrapper from './StyledWrapper'
import { Header, AddressInboxLink, DivThemeColor } from './StyledHeader'
import { MessageLink, SenderContainer, Sender, MessageDate, Subject } from './StyledMessage'
import { type ComponentProps as Props } from '../Types'

const MAX_SHOW_MESSAGES: number = 4
const MAX_SUBJECT_LENGTH: number = 120
const EMPTY_SUBJECT: string = '<bez předmětu>'

export default class EmailFull extends PersistedComponent<Props> {
  handleToggleInbox: Function

  constructor (props: Props) {
    super(props)

    this.persistedKeys = ['showInboxPreview']

    this.state = {
      showInboxPreview: true
    }

    this.handleToggleInbox = this.handleToggleInbox.bind(this)
  }

  handleToggleInbox () {
    this.setState(state => {
      return {
        showInboxPreview: !state.showInboxPreview
      }
    })
  }

  /**
   * @param timestamp
   * @return {string}
   */
  timestampToDate (timestamp: number): string {
    const date = new Date(timestamp * 1000)
    return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`
  }

  /**
   * @param text
   * @param maxLength
   * @param etc
   * @return {string}
   */
  truncate (text: string, maxLength: number = 80, etc: string = '...'): string {
    if (text.length > maxLength) {
      text = text.substr(0, maxLength).trim() + etc
    }
    return text
  }

  render () {
    const {
      inbox: {
        summary: {
          unread = 0
        } = {},
        messages: {
          messages = []
        } = {}
      },
      widget: {
        meta: {
          inbox
        }
      },
      isLoading,
      embedded
    } = this.props
    const {
      showInboxPreview
    } = this.state
    const inboxLink = utmLinkGenerator.createLink(inbox.location, { utmTerm: 'position-0' })

    return (
      <Wrapper embedded={embedded}>
        {isLoading && <Loader/>}
        <Header>
          <AddressInboxLink href={inboxLink}>
            Doručená pošta
          </AddressInboxLink>
          <DivThemeColor>
            <strong>{unreadIndicator(unread)}</strong>
          </DivThemeColor>
        </Header>
        {
          showInboxPreview &&
          messages.slice(0, MAX_SHOW_MESSAGES).map((message, index) => {
            const subject = (message.subject) ? message.subject : EMPTY_SUBJECT

            return (
              <MessageLink key={index} href={message.link}>
                <SenderContainer>
                  <Sender attachment={message.attachment}>
                    {message.sender}
                  </Sender>
                  <Subject read={message.read}>
                    {this.truncate(subject, MAX_SUBJECT_LENGTH)}
                  </Subject>
                  <MessageDate>
                    {this.timestampToDate(message.date)}
                  </MessageDate>
                </SenderContainer>
              </MessageLink>
            )
          })
        }
      </Wrapper>
    )
  }
}
