import styled from 'styled-components'
import Input from 'reactstrap/lib/Input'
import Label from 'reactstrap/lib/Label'

const RememberLabel = styled(Label)`
  color: #333;
  font-size: 13px;
  font-weight: normal;
  line-height: 15px;
  padding: 0 0 0 18px;
  margin: 0;
  cursor: pointer;
  position: relative;
  &:before {
  content: '';
    content: '';
    width: 14px;
    height: 14px;
    background: white;
    position: absolute;
    left: 0;
    top: 0;
    border: 1px solid #898989;
  }
  &:after {
    content: '';
    position: absolute;
    width: 8px;
    height: 5px;
    background: transparent;
    top: 4px;
    left: 3px;
    border: 0;
    transform: rotate(-45deg);
  }
`

const RememberCheckbox = styled(Input)`
  display: none;
  &:checked + ${RememberLabel}:before {
    border: 1px solid ${props => props.theme.email.summary.checkbox.colorChecked};
  }
  &:checked + ${RememberLabel}:after {
    border: 2px solid ${props => props.theme.email.summary.checkbox.colorChecked};
    border-top: none;
    border-right: none;
  }
`

export { RememberCheckbox, RememberLabel }
