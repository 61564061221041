import styled from 'styled-components'
import Button from 'reactstrap/lib/Button'

export default styled(Button)`
  color: ${props => props.theme.email.summary.submit.color};
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  width: 90px;
  height: 70px;
  padding: 0 10px;
  background: ${props => props.theme.email.summary.submit.background};
  border: 1px solid ${props => props.theme.email.summary.submit.border};
  border-radius: ${props => props.theme.email.summary.submit.borderRadius};
  & > span {
    opacity: 0.5;
    margin-left: 8px;
  }
  &:hover {
    color: ${props => props.theme.email.summary.submit.color};
    border: 1px solid ${props => props.theme.email.summary.submit.borderHover};
    background: ${props => props.theme.email.summary.submit.backgroundHover};
  }
`
