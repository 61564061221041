const fontColor = '#333'
const mainColor = '#2f6894'
const highlightColor = '#eb6600'
const darkHighlightColor = '#d05d03'
const buttonColor = '#fff'
const buttonBackgroundColor = '#00467f'
const buttonBorderColor = '#00325c'
const numberColor = '#cf4b00'
const backgroundColor = '#ffdd9b'
const borderColor = '#ffdd9b'
const inboxLink = '#2f7bb1'
const inboxBorder = '#dcdcdc'
const shadowColor = '#00000040'
const emailBoxColor = '#3e5a67'

const buttonStyles = {
  color: buttonColor,
  background: buttonBackgroundColor,
  border: buttonBorderColor,
  hover: {
    background: highlightColor,
    border: darkHighlightColor
  }
}

export default {
  name: 'centrum',
  header: {
    logo: {
      bottom: 10,
      height: 30
    },
    holidayLogo: {
      top: 25
    },
    search: {
      sections: {
        color: mainColor,
        activeColor: highlightColor
      },
      form: {
        autocomplete: {
          color: highlightColor
        },
        input: {
          borderColor: mainColor,
          borderColorFocus: highlightColor,
          color: fontColor
        }
      }
    },
    promolinkheader: {
      color: 'rgb(219, 0, 32)',
      darkColor: 'rgb(0, 0, 0)'
    },
    email: {
      indicatorColor: highlightColor,
      linkColor: mainColor
    }
  },
  covid: {
    textHover: highlightColor
  },
  dynamicLead: {
    firstBox: {
      backgroundColor: buttonBackgroundColor,
      backgroundColorHover: buttonBorderColor,
      color: 'white'
    },
    smallBox: {
      color: fontColor
    }
  },
  mobileTabs: {
    background: '#edf2f6'
  },
  email: {
    summary: {
      background: '#edf2f6',
      registrationLink: {
        color: mainColor,
        colorHover: highlightColor
      },
      checkbox: {
        colorChecked: darkHighlightColor
      },
      submit: {
        color: buttonColor,
        border: buttonBorderColor,
        borderRadius: '0 4px 4px 0',
        background: buttonBackgroundColor,
        borderHover: darkHighlightColor,
        backgroundHover: highlightColor
      },
      input: {
        border: '#d2d2d2',
        borderFocus: highlightColor,
        borderRadius: '0'
      }
    },
    full: {
      color: mainColor,
      backgroundColor: '#f2f6f7',
      number: numberColor,
      bgNumber: backgroundColor,
      borderNumber: borderColor,
      link: inboxLink,
      border: inboxBorder,
      subject: {
        color: mainColor,
        colorHover: highlightColor
      },
      toggleIcon: {
        background: `${STATICS_PATH}images/email/arrow-centrum.png`
      },
      inboxIcon: {
        background: `${STATICS_PATH}images/email/inbox-centrum.svg`
      }
    },
    identita: {
      textColor: emailBoxColor,
      buttonTextColor: buttonColor,
      buttonColor: highlightColor,
      buttonHoverColor: darkHighlightColor
    }
  },
  mobileUserMenu: {
    color: mainColor,
    iconColor: mainColor,
    hoverColor: mainColor,
    borderColor: mainColor
  },
  headerLogin: {
    shadowColor,
    dividerColor: inboxBorder,
    icon: {
      color: buttonBackgroundColor,
      hover: {
        color: highlightColor
      }
    },
    unreadMessagesCount: {
      color: buttonColor,
      backgroundColor: highlightColor,
      zero: {
        backgroundColor: '#aaa'
      }
    },
    newLabel: {
      color: highlightColor
    },
    loginButton: {
      color: buttonBackgroundColor,
      backgroundColor: buttonColor,
      hover: {
        color: highlightColor
      }
    },
    loginModal: {
      backgroundColor: buttonColor,
      title: {
        color: fontColor
      },
      message: {
        color: fontColor
      },
      loginButton: {
        color: buttonColor,
        backgroundColor: buttonBackgroundColor,
        borderColor: buttonBorderColor,
        hover: {
          backgroundColor: highlightColor,
          borderColor: darkHighlightColor
        }
      }
    },
    userMenu: {
      backgroundColor: buttonColor,
      item: {
        color: buttonBackgroundColor,
        hover: {
          color: buttonBackgroundColor,
          backgroundColor: highlightColor
        }
      },
      basicInfo: {
        color: fontColor
      }
    },
    avatar: {
      borderColor: buttonBackgroundColor,
      color: buttonBackgroundColor
    }
  },
  ecoIdLoginPromo: {
    container: {
      backgroundColor: buttonBackgroundColor
    },
    text: {
      color: 'white'
    },
    button: {
      color: 'black',
      backgroundColor: '#edf2f6',
      backgroundHoverColor: highlightColor
    },
    moreInfo: {
      color: 'white',
      hoverColor: highlightColor
    }
  },
  weather: {
    button: buttonStyles
  },
  exchange: {
    button: buttonStyles
  },
  tip: {
    background: '#949494',
    color: '#fff'
  },
  footer: {
    color: fontColor,
    colorLink: fontColor
  },
  services: {
    color: mainColor
  },
  jobs: {
    arrowUrl: `${STATICS_PATH}images/jobs/arrow-centrum.png`,
    color: mainColor,
    colorHover: highlightColor
  },
  newsfeed: {
    button: buttonStyles
  },
  headline: {
    color: mainColor,
    colorHover: highlightColor
  },
  moreLink: {
    color: mainColor,
    colorHover: highlightColor
  },
  link: {
    colorHover: highlightColor
  },
  coronaHighlight: {
    color: highlightColor
  },
  featured: {
    video: {
      backgroundColor: buttonBackgroundColor,
      borderColor: buttonBorderColor,
      color: buttonColor,
      iconInvert: '0%'
    }
  },
  button: buttonStyles,
  regional: {
    color: buttonColor,
    newLabelBorder: darkHighlightColor,
    newLabelBackground: highlightColor
  }
}
