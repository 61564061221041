import styled from 'styled-components'
import Link from '../../../utils/Link'

export default styled(Link)`
  color: ${props => props.theme.email.summary.registrationLink.color};
  text-decoration: underline;
  font-size: 14px;
  line-height: 20px;
  &:hover {
    color: ${props => props.theme.email.summary.registrationLink.colorHover};
  }
`
