const fontColor = '#333'
const mainColor = '#1359c3'
const highlightColor = '#7eaa3d'
const borderColor = '#8dbb4f'
const buttonColor = '#222'
const buttonBackgroundColor = '#bceb70'
const buttonBackgroundColorHover = '#b2e16a'
const widgetBackgroundColor = '#cee2fa'
const numberColor = '#222'
const backgroundColor = '#bceb70'
const inboxLink = '#1359c3'
const inboxBorder = '#999'
const shadowColor = '#00000040'
const defaultBackgroundColor = '#fff'
const emailBoxColor = '#3e5a67'
const userMenuColor = '#7ead07'

const buttonStyles = {
  color: buttonColor,
  background: buttonBackgroundColor,
  border: borderColor,
  hover: {
    background: buttonBackgroundColorHover,
    border: borderColor
  }
}

export default {
  name: 'atlas',
  header: {
    logo: {
      bottom: 0,
      height: 37
    },
    holidayLogo: {
      top: 9
    },
    search: {
      sections: {
        color: '#949494',
        activeColor: highlightColor
      },
      form: {
        autocomplete: {
          color: highlightColor
        },
        input: {
          borderColor: borderColor,
          borderColorFocus: borderColor,
          color: fontColor
        }
      }
    },
    promolinkheader: {
      color: 'rgb(219, 0, 32)',
      darkColor: 'rgb(0, 0, 0)'
    },
    email: {
      indicatorColor: highlightColor,
      linkColor: mainColor
    }
  },
  covid: {
    textHover: highlightColor
  },
  dynamicLead: {
    firstBox: {
      backgroundColor: mainColor,
      backgroundColorHover: '#0f4ca7',
      color: 'white'
    },
    smallBox: {
      color: fontColor
    }
  },
  mobileTabs: {
    background: widgetBackgroundColor
  },
  email: {
    summary: {
      background: widgetBackgroundColor,
      registrationLink: {
        color: mainColor,
        colorHover: highlightColor
      },
      checkbox: {
        colorChecked: highlightColor
      },
      submit: {
        color: buttonColor,
        border: borderColor,
        borderRadius: '0 4px 4px 0',
        background: buttonBackgroundColor,
        borderHover: borderColor,
        backgroundHover: buttonBackgroundColorHover
      },
      input: {
        border: '#999',
        borderFocus: '#888',
        borderRadius: '0'
      }
    },
    full: {
      color: mainColor,
      backgroundColor: '#cee2fa',
      number: numberColor,
      bgNumber: backgroundColor,
      borderNumber: borderColor,
      link: inboxLink,
      border: inboxBorder,
      subject: {
        color: mainColor,
        colorHover: highlightColor
      },
      toggleIcon: {
        background: `${STATICS_PATH}images/email/arrow-atlas.png`
      },
      inboxIcon: {
        background: `${STATICS_PATH}images/email/inbox-atlas.svg`
      }
    },
    identita: {
      textColor: emailBoxColor,
      buttonTextColor: widgetBackgroundColor,
      buttonColor: highlightColor,
      buttonHoverColor: highlightColor
    }
  },
  mobileUserMenu: {
    color: mainColor,
    iconColor: mainColor,
    hoverColor: mainColor,
    borderColor: mainColor
  },
  headerLogin: {
    shadowColor,
    dividerColor: inboxBorder,
    icon: {
      color: userMenuColor,
      hover: {
        color: buttonBackgroundColorHover
      }
    },
    unreadMessagesCount: {
      color: defaultBackgroundColor,
      backgroundColor: buttonBackgroundColorHover,
      zero: {
        backgroundColor: '#aaa'
      }
    },
    loginButton: {
      color: userMenuColor,
      backgroundColor: defaultBackgroundColor,
      hover: {
        color: buttonBackgroundColorHover
      }
    },
    newLabel: {
      color: buttonBackgroundColorHover
    },
    loginModal: {
      backgroundColor: buttonColor,
      title: {
        color: fontColor
      },
      message: {
        color: fontColor
      },
      loginButton: {
        color: buttonColor,
        backgroundColor: buttonBackgroundColor,
        borderColor: borderColor,
        hover: {
          backgroundColor: buttonBackgroundColorHover,
          borderColor: borderColor
        }
      }
    },
    userMenu: {
      backgroundColor: defaultBackgroundColor,
      item: {
        color: userMenuColor,
        hover: {
          color: fontColor,
          backgroundColor: buttonBackgroundColorHover
        }
      },
      basicInfo: {
        color: fontColor
      }
    },
    avatar: {
      borderColor: userMenuColor,
      color: userMenuColor
    }
  },
  ecoIdLoginPromo: {
    container: {
      backgroundColor: buttonBackgroundColor
    },
    text: {
      color: 'black'
    },
    button: {
      color: 'black',
      backgroundColor: widgetBackgroundColor,
      backgroundHoverColor: buttonBackgroundColorHover
    },
    moreInfo: {
      color: fontColor,
      hoverColor: highlightColor
    }
  },
  weather: {
    button: buttonStyles
  },
  exchange: {
    button: buttonStyles
  },
  tip: {
    background: widgetBackgroundColor,
    color: mainColor
  },
  footer: {
    color: fontColor,
    colorLink: fontColor
  },
  services: {
    color: mainColor
  },
  jobs: {
    arrowUrl: `${STATICS_PATH}images/jobs/arrow-atlas.png`,
    color: mainColor,
    colorHover: highlightColor
  },
  newsfeed: {
    button: buttonStyles
  },
  headline: {
    color: mainColor,
    colorHover: highlightColor
  },
  moreLink: {
    color: mainColor,
    colorHover: highlightColor
  },
  link: {
    colorHover: highlightColor
  },
  coronaHighlight: {
    color: highlightColor
  },
  featured: {
    video: {
      backgroundColor: buttonBackgroundColor,
      borderColor: borderColor,
      color: buttonColor,
      iconInvert: '100%'
    }
  },
  button: buttonStyles,
  regional: {
    color: buttonColor,
    newLabelBorder: borderColor,
    newLabelBackground: buttonBackgroundColor
  }
}
