import React from 'react'
import styled from 'styled-components'
import utmLinkGenerator from '../../utils/UTMLinkGenerator'
import { SIZES } from '../../../constants'
import { getLinkTarget } from '../../utils/getLinkTarget'

const Container = styled.a`
  position: relative;
  display: flex;
  align-items: flex-end;
  padding-left: 26px;
  white-space: nowrap;

  &:hover {
    text-decoration: none;
  }

  @media (max-width: ${SIZES.BREAKPOINT_MD - 1}px) {
    margin-bottom: 15px;
  }


  @media (min-width: ${SIZES.BREAKPOINT_MD - 1}px) {
    max-width: 430px;
  }

  @media (max-width: ${SIZES.BREAKPOINT_XS - 1}px) {
    padding-left: 0;
  }
`

const Image = styled.img`
  position: absolute;
  height: 35px;
  width: auto;
  left: 5px;
  bottom: 0;

  @media (min-width: ${SIZES.BREAKPOINT_MD - 1}px) {
    left: 25px;
  }
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 48px;
  padding-top: 5px;
  background-size: 19px 27px;

  p {
    margin: 0;
  }
`

const BoldText = styled.p`
  font-weight: bold;
  color: ${props => props.theme.header.promolinkheader.darkColor};
  font-size: 13px;
  line-height: 16px;
`

const ColorfulText = styled.p`
  font-weight: normal;
  color: ${props => props.theme.header.promolinkheader.color};
  font-size: 13px;

  &:after {
    content: '';
    background: url('${STATICS_PATH}images/promolinkheader/promo_arrow.svg') left top no-repeat;
    background-size: cover;
    width: 14px;
    Height: 14px;
    display: inline-block;
    margin-left: 5px;
    vertical-align: sub;
  }
`

const SelfPromo = ({ widget, layout }) => {
  const {
    utmMedium,
    utmCampaign,
    boxes: [
      {
        title,
        images,
        link,
        annotation
      }
    ]
  } = widget

  const [imageUrl] = Object.values(images)

  return (
    <Container
      href={utmLinkGenerator.createLink(link, {
        utmMedium,
        utmCampaign
      })}
      target={getLinkTarget(layout)}
      rel='noopener'
    >
      <Image src={imageUrl} alt={title}/>
      <TextContainer>
        <BoldText>{title}</BoldText>
        <ColorfulText>{
          annotation
        }
        </ColorfulText>
      </TextContainer>
    </Container>
  )
}

export default SelfPromo
